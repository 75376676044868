import MeetRoom from "./MeetComponents/MeetRoom";
import { useLocation } from 'react-router-dom';
import {Col, Container, Row} from "reactstrap";
export default function Room(){
    const query = new URLSearchParams(useLocation().search);
    const token = query.get('token');
    const urlWs = "wss://meetez.livekit.cloud"
    return(<>
        <Container fluid>
            <MeetRoom token={token} url={urlWs}/>
        </Container>
    </>)
}