import {
    LiveKitRoom, VideoRenderer,
    AudioRenderer, useParticipant, ScreenShareView, StageProps
} from "@livekit/react-components";
import {Participant, Room, Track} from "livekit-client";
import React, {useEffect} from "react";
import RoomView from "./RoomView";
import {Button, Card, CardBody, CardTitle, Col, FormGroup, Row, UncontrolledTooltip} from "reactstrap";
import {Mic, MicOff, Power, Video, VideoOff, Cast, Slash} from "react-feather";
import {useHistory} from "react-router-dom";

export default function MeetRoom({token, url}) {
    const [isScreenShare, setScreenShare] = React.useState(false)
    const [shareTrack, setShareTrack] = React.useState(null)
    const [listUsers, setListUsers] = React.useState([])
    const [userShare, setUserShare] = React.useState()
    let currentRoom = null
    function StageView({roomState}) {
        const {room, participants, audioTracks, isConnecting, error, } = roomState
        setListUsers(participants)
        currentRoom = room
        if (isConnecting) {
            return <RoomView color="info">Connecting...</RoomView>
        }
        if (error) {
            return <RoomView color="danger">Error: {error.message}</RoomView>
        }
        if (!room) {
            return <RoomView>Room closed</RoomView>
        }
        return (
            <RenderRoomView/>
        )
    }

    async function onConnected(room: Room) {
        await room.localParticipant.setCameraEnabled(true);
        await room.localParticipant.setMicrophoneEnabled(true);
    }
    const ControlsView = () => {
        const history = useHistory()
        const onToggleMic = async () => {
            const enabled = currentRoom.localParticipant.isMicrophoneEnabled
            await currentRoom.localParticipant.setMicrophoneEnabled(!enabled)
        }
        const onToggleVideo = async () => {
            const enabled = currentRoom.localParticipant.isCameraEnabled
            await currentRoom.localParticipant.setCameraEnabled(!enabled)
        }
        const onShareScreen = async () => {
            const enabled = currentRoom.localParticipant.isScreenShareEnabled;
            if (!enabled && isScreenShare){
                return
            }
            await currentRoom.localParticipant.setScreenShareEnabled(!enabled, { audio: true });
            setScreenShare(currentRoom.localParticipant.isScreenShareEnabled)
            RenderRoomView()
        }
        const onPowerOff = async () => {
            await currentRoom.disconnect()
            history.push("/meet")
        }

        return (
            <Row className="fixed-bottom text-center">
                <Col className="col-lg-12">
                    <Button color="info" className="btn-icon btn-round mr-2" id="btn-mic" onClick={onToggleMic}>
                        {currentRoom.localParticipant.isMicrophoneEnabled ? <Mic/> : <MicOff/>}
                    </Button>
                    <UncontrolledTooltip target="btn-mic" placement="bottom" delay={1}>
                        {currentRoom.localParticipant.isMicrophoneEnabled ? 'Mic Off' : 'Mic On'}
                    </UncontrolledTooltip>
                    <Button color="success" className="btn-icon btn-round mr-2" id="btn-webcam" onClick={onToggleVideo}>
                        {currentRoom.localParticipant.isCameraEnabled ? <Video/> :  <VideoOff/>}
                    </Button>
                    <UncontrolledTooltip target="btn-webcam" placement="bottom" delay={1}>
                        {currentRoom.localParticipant.isCameraEnabled ? 'Webcam Off' : 'Webcam On'}
                    </UncontrolledTooltip>
                    <Button color="danger" className="btn-icon btn-round mr-2" id="btn-exit" onClick={onPowerOff}>
                        <Power/>
                    </Button>
                    <UncontrolledTooltip target="btn-exit" placement="bottom" delay={1}>
                        Leave room
                    </UncontrolledTooltip>
                    <Button className="btn-icon btn-round" id="btn-share-screen" onClick={onShareScreen}>
                        {currentRoom.localParticipant.isScreenShareEnabled ? <Slash/> :  <Cast/>}
                    </Button>
                    <UncontrolledTooltip target="btn-share-screen" placement="bottom" delay={1}>
                        Screen Share
                    </UncontrolledTooltip>
                </Col>
            </Row>
        )
    }
    const RenderRoomView = () =>{
        if (isScreenShare){
            return (<>
                <Row noGutters={false} className="align-content-center">
                    <Col lg="10">
                        <ShareScreenView track={shareTrack}/>
                    </Col>
                    <Col lg="2" className="text-center">
                        <ListParticipant participants={listUsers}/>
                    </Col>
                </Row>
                <ControlsView/>
            </>)
        }
        return (<>
            <ListParticipant participants={listUsers}/>
            <ControlsView/>
            </>)
    }
    const ListParticipant = ({participants}) => {
        const count = participants.length
        if (count < 1){
            return(<span/>)
        }
        if (count > 1){
            if (isScreenShare){
                return (<>
                    <Card>
                        <CardTitle>
                            <h3 className="text-uppercase">Participants {count}</h3>
                        </CardTitle>
                        <CardBody>
                            {participants.map(participant => (
                                <Col key={participant.sid}>
                                    <CustomParticipantView
                                        key={participant.sid}
                                        participant={participant}
                                        showOverlay={true}
                                        aspectWidth={16}
                                        aspectHeight={9}
                                    />
                                </Col>
                            ))}
                        </CardBody>
                    </Card>

                </>)
            }
            return (
                <Row>
                    {participants.map(participant => (
                        <Col key={participant.sid}>
                            <CustomParticipantView
                                key={participant.sid}
                                participant={participant}
                                showOverlay={true}
                                aspectWidth={16}
                                aspectHeight={9}
                            />
                        </Col>
                    ))}
                </Row>
            )
        }
        return (
            <Row>
                {participants.map(participant => (
                    <Col key={participant.sid}>
                        <CustomParticipantView
                            key={participant.sid}
                            participant={participant}
                            showOverlay={true}
                            aspectWidth={16}
                            aspectHeight={9}
                        />
                    </Col>
                ))}
            </Row>
        )
    }
    const ShareScreenView = ({track}) => {
        if (!track){
            return
        }
        return (<ScreenShareView track={track} width="100%" height="100%"/>)
    }
    const CustomParticipantView = ({participant}) => {
        const {cameraPublication, isLocal, screenSharePublication} = useParticipant(participant)
        if (!cameraPublication || !cameraPublication.isSubscribed || !cameraPublication.track || cameraPublication.isMuted) {
            return null
        }
        if (screenSharePublication){
            setScreenShare(true)
            setShareTrack(screenSharePublication.track)
            setUserShare(participant)
        }else {
            if (isScreenShare && userShare.sid === participant.sid){
                setScreenShare(false)
            }
        }
        return (
            <VideoRenderer track={cameraPublication.track} isLocal={isLocal} objectFit="contain" width="100%"
                           height="100%"/>
        )
    }

    return (<>
        <LiveKitRoom url={url} token={token} onConnected={onConnected} stageRenderer={StageView} controlRenderer={ControlsView}/>
    </>)
}




