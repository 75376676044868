/*!

=========================================================
* BLK Design System PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

export default function DemoFooter() {
  function getYear(){
    return new Date().getFullYear()
  }
  return (
    <>
      <footer className="footer">
        <Container>
          <Row>
            <Col className="mx-auto text-center" md="3">
              <h3>Meet Eazy</h3>
              <div>
                <span>© 2021 - {getYear()} </span>
                <a href="https://facebook.com/macchunquan" target="_blank"><span className="text-white-50">Quan Nguyen</span></a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}
