import {
    Button, Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardTitle,
    Col,
    FormGroup,
    FormText,
    Input,
    Label,
    Row
} from "reactstrap";
import React, {useState, useEffect, ReactElement} from "react";
import {AspectRatio} from 'react-aspect-ratio';
import {createLocalVideoTrack} from "livekit-client";
import { VideoRenderer } from '@livekit/react-core';

export default function SetupInfo({username, setUsername, roomName, onSetRoomName, reSetName, onCreateRoom, onJoin, isRoomName}) {
    const [videoTrack, setVideoTrack] = useState();
    const [videoDevice, setVideoDevice] = useState();
    const [videoEnabled, setVideoEnabled] = useState(false);
    useEffect(() => {
        // enable video by default
        createLocalVideoTrack({
            deviceId: videoDevice?.deviceId,
        }).then((track) => {
            setVideoEnabled(true);
            setVideoTrack(track);
        });
    }, [videoDevice]);
    let videoElement: ReactElement;
    if (videoTrack) {
        videoElement = <VideoRenderer objectFit="cover" width="100%" track={videoTrack} isLocal={true} />;
    } else {
        videoElement = <div className="placeholder" />;
    }
    const [copyText, setCopyText] = useState('Click to copy')
    function getInviteLink(): string {
        return `https://meetez.net/meet?room=${roomName}`
    }
    async function onCopyLink(event){
        event.preventDefault()
        await navigator.clipboard.writeText(getInviteLink());
        setCopyText('Copied')
        setTimeout(() =>{
            setCopyText('Click to copy')
        }, 500)
    }
    return (<Card>
        <CardHeader>
            <AspectRatio className="video-session" ratio={16 / 9}>{videoElement}</AspectRatio>
            <Row>
                <Col>
                    <CardTitle>
                        <h3>Start a Meet</h3>
                    </CardTitle>
                </Col>
            </Row>
        </CardHeader>
        <CardBody>
            <div className="form-row">
                <FormGroup className="col-md-6">
                    <Label for="username">Your Name <span className="text-danger">*</span> </Label>
                    <Input id="username" name="username" value={username} onChange={setUsername} placeholder="You must fill a name"/>
                    <FormText color="muted">
                        Minimum 6 characters
                    </FormText>
                </FormGroup>
                <FormGroup className="col-md-6">
                    <Label for='room_name'>Room Name</Label>
                    <Input id='room_name' type='text' name='room_name'
                           placeholder='Room name'
                           value={roomName} onChange={onSetRoomName}/>
                </FormGroup>
                <FormGroup className="col-md-12" onClick={onCopyLink}>
                    <Label for="invite_link">Invite Url</Label>
                    <Input value={getInviteLink()} readOnly={true} />
                    <FormText color="info">
                        {copyText}
                    </FormText>
                </FormGroup>
            </div>

        </CardBody>
        <CardFooter className="text-center pb-md-5">
            <Col lg="12" md="12">
                <Button color="info" disabled={username.length < 6} block
                        onClick={onJoin}>
                    Join Now
                </Button>
            </Col>
        </CardFooter>
    </Card>)
}