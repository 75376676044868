
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// styles
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/css/nucleo-icons.css";
import "assets/scss/blk-design-system-pro-react.scss?v1.2.0";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";

// presentation pages
import Index from "views/Index.js";
// example pages
import LandingPage from "views/examples/LandingPage.js";
import Meet from "views/Meet.js";
import Room from "./views/Room";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Switch>
      <Route path="/index" render={(props) => <Index {...props} />} />
      <Route
        path="/meet"
        render={(props) => <Meet {...props} />}
      />
        <Route path="/room" exact render={(props) => <Room {...props}/>} />
      <Redirect from="/" to="/index" />
    </Switch>
  </BrowserRouter>
);
