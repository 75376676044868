import React, {useState} from "react";
// reactstrap components
import {
    Container,
    Row,
    Col
} from "reactstrap";
import '@livekit/react-components/dist/index.css';
// core components
import DemoFooter from "components/Footers/DemoFooter.js";
import wretch from 'wretch'

import SetupInfo from "./MeetComponents/SetupInfo";
import {useHistory, useLocation} from "react-router-dom";
import ColorNavbar from "../components/Navbars/ColorNavbar";

export default function Meet() {
    const wrapper = React.useRef(null);
    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        wrapper.current.scrollTop = 0;
        document.body.classList.add("landing-page");
        return function cleanup() {
            document.body.classList.remove("landing-page");
        };
    }, []);
    const query = new URLSearchParams(useLocation().search);
    React.useEffect(() => {
        const name = query.get('name');
        if (name) {
            setUsername(name)
        }
        const room = query.get('room');
        if (room) {
            setRoomName(room)
            setIsRoom(true)
        }
        if (room && name) {
            return onJoin()
        }
    })
    const [isLogin, setIsLogin] = React.useState(false)
    const [isSetRoomName, setIsJoin] = React.useState(false)
    const [username, setUsername] = React.useState('')
    const [roomName, setRoomName] = React.useState('')
    const [urlSv, setUrlSv] = React.useState('')
    const [isRoomName, setIsRoom] = useState(false);

    const apiUrl = "https://api.meetez.net/api"
    const history = useHistory();
    autoSetRoomName()

    function autoSetRoomName(){
        if (roomName.length < 6 && !isRoomName) {
            const uid = makeId(4) + '-' + makeId(10)
            setRoomName(uid)
            setIsRoom(true)
        }
    }

    function onSetName(event) {
        event.preventDefault()
        setUsername(event.target.value)
    }

    function onSetRoomName(event) {
        event.preventDefault()
        setRoomName(event.target.value)
    }

    async function onJoin() {
        const data = await wretch(apiUrl + '/token').post({
            username,
            room: roomName
        }).json(res => {
            return res.token
        })
        history.push("/room?token=" + data)
    }

    function reSetName() {
        setRoomName('')
        setIsLogin(false)
    }

    function onCreateRoom() {

    }

    function makeId(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    return (
        <>
            <ColorNavbar/>
            <div className="wrapper" ref={wrapper}>
                <div className="page-header">
                    <img
                        alt="..."
                        className="path"
                        src={require("assets/img/blob.png")}
                    />
                    <img
                        alt="..."
                        className="path2"
                        src={require("assets/img/path2.png")}
                    />
                    <img
                        alt="..."
                        className="shapes triangle"
                        src={require("assets/img/triunghiuri.png")}
                    />
                    <img
                        alt="..."
                        className="shapes wave"
                        src={require("assets/img/waves.png")}
                    />
                    <img
                        alt="..."
                        className="shapes squares"
                        src={require("assets/img/patrat.png")}
                    />
                    <img
                        alt="..."
                        className="shapes circle"
                        src={require("assets/img/cercuri.png")}
                    />
                    <Container>
                        <Row className="align-content-center">
                            <Col className="mx-auto" lg="8" md="6" >
                                <SetupInfo
                                    username={username}
                                    setUsername={onSetName}
                                    roomName={roomName}
                                    onSetRoomName={onSetRoomName}
                                    reSetName={reSetName}
                                    onCreateRoom={onCreateRoom}
                                    onJoin={onJoin}
                                    isRoomName={isRoomName}
                                />
                            </Col>
                        </Row>
                    </Container>
                </div>

                <DemoFooter/>
            </div>
        </>
    );
}
